<script setup>
import { loginViews, useLoginStore } from '@@/stores/Login';

definePageMeta({ layout: 'guest' });

const loginStore = useLoginStore();

loginStore.setCredentials({ email: null, password: null });
loginStore.setLoginView(loginViews.forgotPasswordEnterPassword);
</script>

<template>
  <PageContent
    custom-container-class="container page-content"
  >
    <div class="login-container">
      <SettingsPageHeader>
        Change Password
      </SettingsPageHeader>
      <div>
        <EnterPasswordCard
          card-class-names="tw-flex tw-flex-col tw-justify-center"
          :is-full-height="true"
        />
      </div>
      <div class="tw-pt-4">
        <LoginActionsCard />
      </div>
    </div>
  </PageContent>
</template>

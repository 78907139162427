<script setup>
import { loginViews, useLoginStore } from '@@/stores/Login';
import { useLoginView } from '@@/composables/useLoginView';
import EnterEmailCard from '@@/components/Login/EnterEmailCard.vue';
import LinkSentCard from '@@/components/Login/LinkSentCard.vue';

definePageMeta({ layout: 'guest' });

const { isForgotPasswordEnterEmailView, isForgotPasswordLinkSentView } = useLoginView();
const { recaptchaPublicKey } = useRuntimeConfig().public;

useHead({
  script: [{
    src: `https://www.google.com/recaptcha/api.js?render=${recaptchaPublicKey}`,
  }],
});

const pageTitle = computed(() => isForgotPasswordLinkSentView.value ? 'Check Your Email' : 'Reset Password');

const view = computed(() => {
  if (isForgotPasswordEnterEmailView.value) {
    return EnterEmailCard;
  }

  if (isForgotPasswordLinkSentView.value) {
    return LinkSentCard;
  }

  return null;
});

const loginStore = useLoginStore();
loginStore.setCredentials({ password: null });
loginStore.setLoginView(loginViews.forgotPasswordEnterEmail);
</script>

<template>
  <PageContent
    custom-container-class="container page-content"
  >
    <div class="login-container">
      <SettingsPageHeader>
        {{ pageTitle }}
      </SettingsPageHeader>
      <div>
        <transition
          enter-active-class="fade-enter-transition"
          enter-from-class="fade-out"
          leave-active-class="fade-leave-transition"
          leave-to-class="fade-out"
          mode="out-in"
        >
          <component
            :is="view"
            card-class-names="tw-flex tw-flex-col tw-justify-center"
            :is-full-height="true"
          />
        </transition>
      </div>
      <div class="tw-pt-4">
        <LoginActionsCard />
      </div>
    </div>
  </PageContent>
</template>

<script setup>
import { bannerTypes } from '@@/components/Common/Banner.vue';
import { loginViews, useLoginStore } from '@@/stores/Login';
import { useLoginView } from '@@/composables/useLoginView';
import { useUserStore } from '@@/stores/User';
import DeleteAccountForm from '@@/components/Settings/DeleteAccountForm.vue';
import LinkSentCard from '@@/components/Login/LinkSentCard.vue';

definePageMeta({ layout: 'authorized' });

const { $toast } = useNuxtApp();

const loginStore = useLoginStore();
const userStore = useUserStore();

const { isDeleteAccountView, isDeleteAccountLinkSentView } = useLoginView();
const banner = computed(() => userStore.banner);

const sectionTitle = computed(() => isDeleteAccountLinkSentView.value ? 'Check Your Email' : 'Delete Account');

const view = computed(() => {
  if (isDeleteAccountView.value) {
    return DeleteAccountForm;
  }

  if (isDeleteAccountLinkSentView.value) {
    return LinkSentCard;
  }

  return null;
});

onMounted(async () => {
  if (banner.value && banner.value.type === bannerTypes.error) {
    loginStore.setLoginView(loginViews.deleteAccount);
    return;
  }

  let linkSent;

  try {
    linkSent = await loginStore.makeSettingsDeleteLinkRequest();
  }
  catch (e) {
    linkSent = false;
  }

  if (!linkSent) {
    // TODO: Maybe it would be better to display the error message in a card? But that's not a UX
    // that's been used yet so we'll stick with a toast message.
    $toast.open({
      message: 'Error! Unable to send email.',
      type: 'error',
    });
  }
});

loginStore.setLoginView(loginViews.deleteAccountLinkSent);
</script>

<template>
  <PageContent>
    <SettingsPageHeader />
    <SettingsNavigation
      :selected-tab="0"
    />
    <SettingsSectionTitle>
      {{ sectionTitle }}
    </SettingsSectionTitle>
    <div>
      <transition
        enter-active-class="fade-enter-transition"
        enter-from-class="fade-out"
        leave-active-class="fade-leave-transition"
        leave-to-class="fade-out"
        mode="out-in"
      >
        <component
          :is="view"
          :key="loginView"
        />
      </transition>
    </div>
    <div class="tw-mt-4">
      <LoginActionsCard
        v-if="isDeleteAccountLinkSentView"
      />
    </div>
  </PageContent>
</template>
